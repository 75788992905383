import type { NextPageWithLayout } from './_app';

import GeneralLayout from '../layouts/general-layout/general-layout.component';
import ErrorPage from '../components/error-page/error-page.component';

const NotFoundPage: NextPageWithLayout = () => {
  return <ErrorPage code={404} />;
};

NotFoundPage.getLayout = (page) => {
  return <GeneralLayout pageTitle="Page not found">{page}</GeneralLayout>;
};

export default NotFoundPage;
