import Image from 'next/image';
import { useRouter } from 'next/router';

import { Button } from '@gate-academy/react';

import type { FC } from 'react';

import { ErrorMain, ImageContainer } from './error-page.styles';

const ErrorPage: FC<{ code: 404 | 500 }> = ({ code }) => {
  const router = useRouter();

  const errorMessage =
    code === 500 ? 'Something went really wrong' : 'Page not found';

  const altText =
    code === 500
      ? 'internal server error illustration'
      : 'not found error illustration';

  return (
    <ErrorMain>
      <h1>Whoopsie!!! {errorMessage}</h1>

      <ImageContainer>
        <Image
          src={`/${code}.svg`}
          alt={altText}
          fill
          style={{ objectFit: 'contain' }}
        />
      </ImageContainer>

      <Button blackBg noBorderRadius largeFont onClick={() => router.push('/')}>
        Go home
      </Button>
    </ErrorMain>
  );
};

export default ErrorPage;
